

class InstanceDataUser{

    rol = ''
    export = false
    price = false

    getRol(){
        return this.rol
    }

    getExport(){
        return this.export
    }

    getPrice(){
        return this.price
    }

    setRol(rol){
        this.rol = rol
    }

    setExport(val){
        this.exportacion = val
    }

    setAttributes(valExport,valPrecio){
        this.export = valExport === 'true' ? true : false
        this.price = valPrecio === 'true' ? false : true
    }
}
const dataUser = new InstanceDataUser();

export default dataUser;