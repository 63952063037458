import React from 'react'
import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileArrowDown} from "@fortawesome/free-solid-svg-icons";

const ExportCSV = ({csvData, fileName,columns,typeReport}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (csvData, fileName) => {
        let newData = [];
        //reporte completo
        if (typeReport ===1){
            newData = csvData.map((product) => {
                let ul= /((<ul>|<ol>)|(<\/li>|<br \/>)|(<\/ul>|<\/ol>))/g
                let li= /(<li>)/g
                return Object.assign({},product,{
                    Caracteristicas: product.Caracteristicas.replace(ul,"").replace(li,"  -- "),
                    Especificaciones: product.Especificaciones.replace(ul,"").replace(li,"  -- "),
                })
            });
        }else{
            //reporte basico
            newData = csvData.map((product) => {
                return Object.assign({
                    "Articulo": product.Articulo,
                    "Descripción": product.Descripcion,
                    "Precio":product.Precio,
                    "Moneda":product.Moneda,
                    "Tipo Cambio":product.TipoCambio,
                    "Disponible":product.Disponible,
                    "Almacén":product.Almacen,
                    "Grupo":product.Grupo,
                    "Se Compra ICM":product.SeCompraICM,
                    "Estatus ICM":product.EstatusICM,
                })
            });
        }

        console.log("datos a exportar ::::" ,newData )

        const ws = XLSX.utils.json_to_sheet(newData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
      <Button size="sm" variant="primary" onClick={(e) => exportToCSV(csvData,fileName)}>
          <FontAwesomeIcon icon={faFileArrowDown}/>&nbsp; Exportar</Button>
    )
}


export default ExportCSV;