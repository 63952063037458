import React, {useEffect, useState} from 'react'


export const MobileView = ({renderWithFragment, children, ...props}) => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
            setIsMobile(true);
        }
    }, [])

    return isMobile ? (
      renderWithFragment ? (
        <>{children}</>
      ) : (
        <div {...props}>{children}</div>
      )
    ) : null;
}


export const Orientation = {
    isLandScape: window.matchMedia("(orientation: landscape)").matches ? true: false
}

export const BrowserView = ({renderWithFragment, children, ...props}) => {

    const [isBrowser, setIsBrowser] = useState(false);

    useEffect(() => {
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
            setIsBrowser(true);
        }
    }, [])

    return isBrowser ? (
      renderWithFragment ? (
        <>{children}</>
      ) : (
        <div {...props}>{children}</div>
      )
    ) : null;
}