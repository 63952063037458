import React, {memo, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import services from "../../services/Services";
import {useNavigate} from "react-router-dom";

const AlertSession = () => {

    const [show, setShow] = useState(false)
    const navigate = useNavigate();

    const handlerCancel = () => setShow(false)

    const handlerSessionClose = () => {
        navigate('/')//produccion
        //navigate('/pruebasicm/') //pruebas
        services.deleteTokenSession()
    }

    useEffect(() => {

        const timer = setInterval(() => {
            setShow(true)
            //services.deleteTokenSession();
        }, 1800000)

        return () => clearInterval(timer)

    }, [])


    return (
      <>
          {show === true &&
            <Modal show={show} onHide={handlerCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Sesión</Modal.Title>
                </Modal.Header>
                <Modal.Body>La sesión ha caducado, ¿quieren continuar o cerrar sesión?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlerCancel}>
                        Continuar
                    </Button>
                    <Button variant="primary" onClick={handlerSessionClose}>
                        Cerrar Sesión
                    </Button>
                </Modal.Footer>
            </Modal>
          }
      </>
    )
}

export default memo(AlertSession)