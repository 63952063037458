import {NavLink} from "react-router-dom";
import React from "react";
import UserService from "../../services/UserService";
import logoIcintracom from "../components/intracom.png";
import MenuRoles from "../../utils/MenuRoles";
import services from "../../services/Services";
import InstanceDataUser from "../../services/InstanceDataUser";
import dataUser from "../../services/InstanceDataUser";

class OptionMenu extends React.Component {

    constructor() {
        super();
        this.state = {
            options: [],
            nameRol:''
        }
    }

    componentDidMount() {
        let valOptions = this._getRol();
        this.setState({options: valOptions});
    }


    render() {

        return (<div id="menu">

            <div className="pure-menu">
                <span className="pure-menu-heading">
                    <img alt="logo icintracom" src={logoIcintracom} width="130" heigth="45"/>
                    </span>
                <ul className="pure-menu-list">
                    {this.state.options}
                </ul>
            </div>
        </div>)
    }

    _getRol(){
        services.getRoleById(UserService.getUserID()).then(response=>{
            let {id,name} = response.data[0]
            dataUser.setRol(name)
            this.setState({nameRol: name});
            this._getModules(id,name)
        }).catch((e)=>console.log("icintracom error:::",e.message))
    }

    _getModules(idRol) {
        return  services.getAttributesRoles(idRol).then(response=>{
            let {exportacion,fechaCreacion,precio,modulos} = response.data.attributes
            let exp = exportacion ? exportacion[0] : false
            let pre = precio ? precio[0] : false
            InstanceDataUser.setAttributes(exp,pre)
            modulos.sort( function(a, b){
                if(a === "roles"){
                    return -1;
                }
                if (a < b) {
                    return 1;
                }
                if (a > b) {
                    return -1;
                }
                return 0
            });
            let valOptions = modulos.map((option,index)=>{
                //  console.log("MODULO:::",option)
                let name = this.camelize(option)
                return (
                  <li className="pure-menu-item" key={index}>
                      <NavLink to={`${MenuRoles.menu[option]}`} className="pure-menu-link">{name}</NavLink>
                  </li>

                )

            })
            this.setState({options: valOptions});
        })
    }

    camelize(text) {
        text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
        return text.substr(0, 1).toUpperCase() + text.substr(1);
    }
}

export default OptionMenu;