import React from "react";
import {Button, Col, Container, Row, Spinner, ToggleButtonGroup,ToggleButton} from "react-bootstrap";
import styles from "../utils/Styles";
import Datatable from "react-data-table-component";
import services from "../services/Services";
import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard} from "@fortawesome/free-solid-svg-icons";
import InstanceDataUser from "../services/InstanceDataUser";
import ExportCSV from "./components/ExportCSV";

const CustomLoader = () => (
  <div style={{ padding: '24px' }}>
      <Spinner animation="border" variant="primary"/>
      &nbsp;<b><label>Cargando...</label></b>
  </div>
);

class Existencias extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataProducts: [],
            export: false,
            legend: '',
            loading:true,
            arrayFilter :[],
            fileName:"Inventario",
            report: 2
        }
        this.onKeyDown = this.onKeyDown.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    columns = [
        {name: "Articulo", selector: row => row.Articulo, sortable: true,width:'100px'},
        {name: "Descripcion", selector: row => row.Descripcion, sortable: true, width:'400px'},
        {name: "Precio", selector: row => row.Precio, sortable: true, width:'90px', omit: InstanceDataUser.getPrice()},
        {name: "Moneda", selector: row => row.Moneda, sortable: true,width:'100px'},
        {name: "Tipo Cambio", selector: row => row.TipoCambio, sortable: true,width:'125px'},
        {name: "Disponible", selector: row => row.Disponible, sortable: true,width:'130px'},
        {name: "Almacén", selector: row => row.Almacen, sortable: true,width:'100px'},
        {name: "Grupo", selector: row => row.Grupo, sortable: true,width:'100px'},
        {name: "Se Compra ICM", selector: row => row.SeCompraICM, sortable: true,width:'100px'},
        {name: "Estatus ICM", selector: row => row.EstatusICM, sortable: true,width:'100px'},
        {name: "UPC", selector: row => row.UPC, sortable: true,width:'100px'},
        {name: "Características", selector: row => row.Caracteristicas, sortable: true, width:'700px',
            cell: row =>  <div style={{height:'140px',overflow:'auto'}} >
                <label style={{display: 'block',whiteSpace:'pre-wrap'}} dangerouslySetInnerHTML={{__html:row.Caracteristicas }}></label>
            </div>
        },
        {name: "Especificaciones", selector: row => row.Especificaciones, sortable: true, width:'300px',
            cell: row => <div style={{height:'150px',overflow:'auto'}}> <label dangerouslySetInnerHTML={{__html:row.Especificaciones}}></label></div>
        },
        {name: "Dimensiones", selector: row => row.Dimensiones, sortable: true,width:'150px',wrap: true},
        {name: "Peso ", selector: row => row.Peso, sortable: true,  export: true,width:'100px'},
        {name: "Ficha Tecnica", selector: row => row.FichaTecnica, sortable: true, width:'350px',
            cell: row => <a target='_blank'rel="noreferrer"  href={row.FichaTecnica}>{row.FichaTecnica}</a>
        },
        {name: "Imágenes", selector: row => row.Imagenes, width:'100px',
            cell: row => <img src={row.Imagenes} alt="no disponible" width="100" height="80"/>},
        {name: "Product Name", selector: row => row.ProductName, sortable: true,  width:'350px',wrap: true},
        {name: "Sku", selector: row => row.Sku, sortable: true,width:'100px'},
        {name: "Familia", selector: row => row.Familia, sortable: true,width:'100px'}
    ]

    columnsB = [
        {name: "Articulo", selector: row => row.Articulo, sortable: true,width:'90px',  report: 2},
        {name: "Descripcion", selector: row => row.Descripcion, sortable: true, wrap: true,report: 2},
        {name: "Precio", selector: row => row.Precio, sortable: true,width:'90px',  omit: InstanceDataUser.getPrice(),report: 2},
        {name: "Moneda", selector: row => row.Moneda, sortable: true,  report: 2},
        {name: "Tipo Cambio", selector: row => row.TipoCambio, sortable: true, report: 2},
        {name: "Disponible", selector: row => row.Disponible, sortable: true,   report: 2},
        {name: "Almacén", selector: row => row.Almacen, sortable: true,  report: 2},
        {name: "Grupo", selector: row => row.Grupo, sortable: true,   report: 2},
        {name: "Se Compra ICM", selector: row => row.SeCompraICM, sortable: true,  report: 2},
        {name: "Estatus ICM", selector: row => row.EstatusICM, sortable: true, report: 2}
    ]


    componentWillMount() {
        let exp = InstanceDataUser.getExport()
        this.setState({export: exp})
        this.getProducts()
        window.addEventListener('change', this.onChange);
        //window.addEventListener('keydown', this.onKeyDown);
    }

    getProducts(){
        this.setState({dataProducts:[],loading: true })

        services.getAllProducts().then(response => {

            let legend = "Inventario actualizado "+response.data.Fecha
            const clearProducts = response.data.Products.map((product) => {
                return Object.assign({},product,{
                    Imagenes: Array.isArray(product.Imagenes) && product.Imagenes[0],
                })
            });
            this.setState({
                dataProducts: clearProducts,
                arrayFilter: clearProducts,
                legend: legend,
                loading: false
            })


        })
    }

    onKeyDown(e) {
        //e.stopPropagation();
        // e.preventDefault();
        if(e.target.name === "filterDataTable"){
            console.log("ondrag enter",e.target.value)
            console.log("products down",this.state.dataProducts)
        }
    }

    onChange(e){
        if(e.target.name === "filterDataTable"){
            let fil =String(e.target.value)
            if(fil === '0'){
                this.setState({arrayFilter: this.state.dataProducts})
            } else{
                let arrayFil = this.state.dataProducts.filter(product => product.UPC.includes(fil))
                this.setState({arrayFilter: arrayFil})
            }
        }
    }

    handleChange(val){
        this.setState({report: val })
    }

    render() {

        return(
          <>
              <Container fluid>
                  <Row className="justify-content-md-center">

                      <Col sm={10}>
                          <div style={styles.columnText}>
                              <h1 id="lbl_title_admin_existencias">Existencias</h1>
                              {this.state.report === 1 &&
                                <label style={{color:"white",background:"orangered"}}>
                                    Debido a las especificaciones, la información de los artículos
                                    se puede encontrar más abajo en la celda.
                                </label>
                              }
                          </div>
                      </Col>
                      <Col sm={1}></Col>
                  </Row>
              </Container>
              <Container fluid>
                  {

                      this.state.loading === false ?
                        <Row>
                            <Col sm={1}/>
                            <Col sm={3}>
                                <Button size="sm" variant="primary" onClick={() => {this.getProducts()}}>
                                    <FontAwesomeIcon icon={faClipboard}/>&nbsp;Actualizar Inventario
                                </Button>
                            </Col>
                            <Col sm={5}><b><label>{this.state.legend}</label></b></Col>
                            <Col sm={3}>
                                <ExportCSV csvData={this.state.arrayFilter}
                                           fileName={this.state.fileName}
                                           typeReport={this.state.report}
                                           columns={this.state.report === 2 ? this.columnsB : this.columns}/>
                                {' '}
                                <ToggleButtonGroup size="sm" type="radio"  name="typeReport"
                                                   defaultValue={this.state.report}
                                                   onChange={(e) => {this.handleChange(e)}}>
                                    <ToggleButton id="tbg-radio-1" value={1}>
                                        Completo
                                    </ToggleButton>
                                    <ToggleButton id="tbg-radio-2" value={2}>
                                        Básico
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Col>


                        </Row>
                        : <Row className="justify-content-md-center"> </Row>

                  }
                  <Row>
                      <Col sm={12}>
                          <div style={styles.topRow}></div>
                      </Col>
                  </Row>

                  {
                      this.state.report === 2 ?
                        <Row className="justify-content-md-center">
                            <Col sm={11}>
                                <DataTableExtensions
                                  columns={this.columnsB}
                                  data={this.state.dataProducts}
                                  exportHeaders={false}
                                  print={false}
                                  export={false}
                                  filterPlaceholder={"Buscar Producto"}>
                                    <Datatable
                                      columns={this.columnsB}
                                      data={this.state.dataProducts}
                                      pagination
                                      fixedHeader
                                      fixedHeaderScrollHeight="410px"
                                      highlightOnHover={true}
                                      striped
                                      defaultSortField={"UPC"}
                                      defaultSortAsc={false}
                                      progressPending={this.state.loading}
                                      progressComponent={<CustomLoader />}
                                      noDataComponent={"No existen registros"}
                                      paginationComponentOptions={{
                                          rowsPerPageText: 'Registros por página:',
                                          rangeSeparatorText: 'de'
                                      }}
                                    />
                                </DataTableExtensions>
                            </Col>
                        </Row>
                        :
                        <Row className="justify-content-md-center">
                            <Col sm={11}>
                                <DataTableExtensions
                                  columns={this.columns}
                                  data={this.state.dataProducts}
                                  exportHeaders={false}
                                  print={false}
                                  export={false}
                                  filterPlaceholder={"Buscar Producto"}>
                                    <Datatable
                                      columns={this.columns}
                                      data={this.state.dataProducts}
                                      pagination
                                      fixedHeader
                                      fixedHeaderScrollHeight="410px"
                                      highlightOnHover={true}
                                      striped
                                      defaultSortField={"UPC"}
                                      defaultSortAsc={false}
                                      progressPending={this.state.loading}
                                      progressComponent={<CustomLoader />}
                                      noDataComponent={"No existen registros"}
                                      paginationComponentOptions={{
                                          rowsPerPageText: 'Registros por página:',
                                          rangeSeparatorText: 'de'
                                      }}
                                    />
                                </DataTableExtensions>
                            </Col>
                        </Row>
                  }
              </Container>
          </>
        )
    }

}

export default Existencias;