import React from 'react';
import {BrowserView, MobileView, Orientation} from "../DetectDevice";
import AdminUsers from "../../AdminUsers";
import {ImgRotate} from "../ImgRotate"

export const ProxyUsers = () => {
  let isLandScape = Orientation.isLandScape
  return (
    <>
      <BrowserView>
        <AdminUsers/>
      </BrowserView>
      <MobileView>
        {!isLandScape && <ImgRotate/>}
        {isLandScape && <AdminUsers/>}
      </MobileView>
    </>
  )
}