import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPeopleGroup} from "@fortawesome/free-solid-svg-icons"
import React, {useRef, useState} from "react";
import {Button, Col, Form, FormControl, Modal, Row, Container} from "react-bootstrap";
import services from "../../services/Services";
import AlertIcintracom from "./AlertIcintracom";


/**
 *
 * @param id
 * @param label
 * @param help
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const FieldGroupRol = ({id, label, ...props}) => {
    return (

      <Form.Group as={Row} className="mb-4" controlId={id}>
          <Form.Label column sm="4">
              {label}
          </Form.Label>
          <Col sm="8">
              <FormControl {...props} />
          </Col>
      </Form.Group>

    )
}
/**
 *
 * @param id
 * @param label
 * @param help
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CreateCheckbox = ({id, label, help, ...props}) => {
    return (

      <Form.Group as={Row} className="mb-3" controlId={id}>

          <Form.Label column sm="3">
              {label}
          </Form.Label>

          <Form.Check {...props}  />

      </Form.Group>
    )

}


const AddRol = ({getRoles,handlerOpenAlertAdd}) => {
    const [show, setShow] = useState(false)
    const [alerts, setShowAlert] = useState({
        alertSuccess: false,
        alertError: false,
        alertErrorDescription: ""
    })
    const formRef = useRef()

    const [roles, setClicksChk] = useState({
        nameRol: false,
        descripRol: '',
        activateRoles: null,
        activateUsers: null,
        activateExist: null,
        activateExport: false,
        activateTokens: null,
        activatePedidos: null,
        activatePrecio: false
    })

    const handleShowAlertError = (txt) => setShowAlert({...alerts, alertError: true, alertErrorDescription: txt})
    const handleCloseAlertError = () => setShowAlert({...alerts, alertError: false})

    const handleClose = () => {
        handleCloseAlertError()

        setTimeout(() => {
            setShow(false)
            getRoles()
            setClicksChk({
                nameRol: false,
                descripRol: '',
                activateRoles: null,
                activateUsers: null,
                activateExist: null,
                activateExport: null,
                activePrecio: null,
                activateTokens: null,
                activatePedidos: null
            })
        }, 900)

    };
    const handleShow = () => {
        setShow(true)

    };



    const onAddRol = (e) => {
        e.preventDefault()
        const body = {
            "name": roles.nameRol,
            "description": roles.descripRol
        }
        let todayFin =''
        services.getDate().then(response => {
            let today = response.data.Fecha.split(" ")
            todayFin = today[0]
        }).catch(error => {
            handleShowAlertError("¡Error al obtener fecha!")
        })
        services.addRole(body).then(response => {
            services.getRoleJusCreated(roles.nameRol).then(response => {
                const idRolJustCreated = response.data.id
                const dataRolesActivated = [roles.activateRoles,
                    roles.activateUsers, roles.activateTokens, roles.activateExist, roles.activatePedidos]
                const body = {
                    "id": idRolJustCreated,
                    "name": roles.nameRol,
                    "description": roles.descripRol,
                    "composite": false,
                    "clientRole": true,
                    "containerId": "2758d422-8dff-4b47-9706-40559e829d47",
                    "attributes": {
                        "fechaCreacion": [todayFin],
                        "modulos": dataRolesActivated.filter(Boolean),
                        "exportacion": [roles.activateExport],
                        "precio": [roles.activatePrecio]
                    }
                }
                services.updateAttributeRole(idRolJustCreated, body).then(response => {
                    console.log("regreso los atributos :", response)
                    //handleShowAlert()
                    handleClose()
                    setTimeout(() => {
                        handlerOpenAlertAdd()
                    }, 1000)



                }).catch(error => {
                    handleShowAlertError("¡Error asignar modulos!")
                    console.log("icintracom - agregar rol:", error.message)
                })

            }).catch(error => {
                handleShowAlertError("¡Error al crear rol!")
                console.log("icintracom - agregar rol:", error.message)
            })


        }).catch(error => {
            handleShowAlertError("¡El rol ya existe!")
            console.log("icintracom - agregar rol:", error.message)
        })

    }


    return (
      <>
          <Button size="sm" variant="primary" onClick={handleShow}>
              <FontAwesomeIcon icon={faPeopleGroup}/>&nbsp;Agregar Rol
          </Button>

          <Modal show={show} onHide={handleClose}>

              <AlertIcintracom title={"Rol"}
                               text={alerts.alertErrorDescription}
                               show={alerts.alertError}
                               close={handleCloseAlertError}
                               type={"danger"}/>

              <Modal.Header closeButton>
                  <Modal.Title>Agregar Rol</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  <fieldset className="field_set">
                      <Form ref={formRef} onSubmit={onAddRol}>
                          <FieldGroupRol
                            required
                            id="frmNameRol"
                            type="text"
                            label="Rol *"
                            placeholder="rol"
                            val={roles.nameRol}
                            onChange={(e) => setClicksChk({...roles, nameRol: e.target.value})}
                          />
                          <FieldGroupRol
                            required
                            id="frmDescriptionRol"
                            type="text"
                            label="Descripción *"
                            placeholder="Descripción"
                            val={roles.descripRol}
                            onChange={(e) => setClicksChk({...roles, descripRol: e.target.value})}
                          />
                          <Container fluid>
                              <Row className="justify-content-md-left">
                                  <Col sm={1}></Col>
                                  <Col sm={7}>
                                      <CreateCheckbox
                                        id="frmChkRol"
                                        type="checkbox"
                                        label="Roles"
                                        placeholder="rol"
                                        value={"roles"}
                                        onChange={(e) => setClicksChk({...roles, activateRoles: e.target.value})}
                                      />
                                      <CreateCheckbox
                                        id="frmChkUsers"
                                        type="checkbox"
                                        label="Usuarios"
                                        placeholder="usuarios"
                                        value={"usuarios"}
                                        onChange={(e) => setClicksChk({...roles, activateUsers: e.target.value})}
                                      />
                                      <CreateCheckbox
                                        id="frmChkExist"
                                        type="checkbox"
                                        label="Existencias"
                                        placeholder="existencias"
                                        value={"existencias"}
                                        onChange={(e) => setClicksChk({...roles, activateExist: e.target.value})}
                                      />
                                      <CreateCheckbox
                                        id="frmChkPrecio"
                                        type="checkbox"
                                        label="Precio"
                                        placeholder="precio"
                                        onChange={(e) => setClicksChk({...roles, activatePrecio: e.target.checked})}
                                      />
                                  </Col>
                                  <Col sm={4}>
                                      <CreateCheckbox
                                        id="frmChkTokens"
                                        type="checkbox"
                                        label="Tokens"
                                        placeholder="tokens"
                                        value={"tokens"}
                                        onChange={(e) => setClicksChk({...roles, activateTokens: e.target.value})}
                                      />
                                      <CreateCheckbox
                                        id="frmChkPedidos"
                                        type="checkbox"
                                        label="Pedidos"
                                        placeholder="pedidos"
                                        value={"pedidos"}
                                        onChange={(e) => setClicksChk({...roles, activatePedidos: e.target.value})}
                                      />
                                      <CreateCheckbox
                                        id="frmChkExport"
                                        type="checkbox"
                                        label="Exportar"
                                        placeholder="exportar"
                                        onChange={(e) => setClicksChk({...roles, activateExport: e.target.checked})}
                                      />
                                  </Col>
                              </Row>
                          </Container>
                          <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                  Cerrar
                              </Button>
                              <Button variant="primary" type="submit">
                                  Aceptar
                              </Button>
                          </Modal.Footer>
                      </Form>
                  </fieldset>
              </Modal.Body>

          </Modal>
      </>
    )
}

export default AddRol;