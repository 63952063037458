import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import Datatable from "react-data-table-component";
import services from "../services/Services";
import {Modal, Button, Container, Row, Col, Form, Spinner} from 'react-bootstrap';
import AddRol from "./components/AddRol";
import styles from "../utils/Styles"
import AlertIcintracom from "./components/AlertIcintracom";

let allDataRoles = []
let labelModules = {
    "modRoles": "roles",
    "modUsers": "usuarios",
    "modTokens": "tokens",
    "modExist": "existencias",
    "modPedidos" : "pedidos"
}

const CustomLoader = () => (
  <div style={{ padding: '24px' }}>
      <Spinner animation="border" variant="primary"/>
      &nbsp;<b><label>Cargando...</label></b>
  </div>
);

class AdminRoles extends React.Component {

    constructor(props) {
        super(props);
        this.state = ({
            dataRoles: [],
            showUpdateAlert: false,
            showUpdateRol: false,
            showAlertAddRol: false,
            openModal: false,
            loading:true,
            idRol: "",
            nameRol: ""
        })
        this.getRoles = this.getRoles.bind(this)

        this.columns = [
            {name: "ID", selector: row => row.id, omit: true, sortable: true},
            {name: "Nombre", selector: row => row.name, sortable: true, wrap: true},
            {name: "Descripción", selector: row => row.description, sortable: true, width: '180px', wrap: true},
            {name: "Fecha Creación", selector: row => row.fechaCreacion, sortable: true},
            {
                name: "Roles", selector: row => row.modRoles, sortable: true,
                cell: (row, index) => (
                  <Form.Check type="switch" defaultChecked={row.modRoles}
                              onChange={(e) => {
                                  this.handleChangeChk(row, e, index, "modRoles")
                              }}
                  />

                )
            },
            {
                name: "Usuarios", selector: row => row.modUsers, sortable: true,
                cell: (row, index) => (
                  <Form.Check type="switch" defaultChecked={row.modUsers}
                              onChange={(e) => {
                                  this.handleChangeChk(row, e, index, "modUsers")
                              }}
                  />

                )
            },
            {
                name: "Tokens", selector: row => row.modTokens, sortable: true,
                cell: (row, index) => (
                  <Form.Check type="switch" defaultChecked={row.modTokens}
                              onChange={(e) => {
                                  this.handleChangeChk(row, e, index, "modTokens")
                              }}
                  />
                )
            },
            {
                name: "Existencias", selector: row => row.modExist, sortable: true,
                cell: (row, index) => (
                  <Form.Check type="switch" defaultChecked={row.modExist}
                              id={"modExist"+index}
                              onChange={(e) => {
                                  this.handleChangeChk(row, e, index, "modExist")
                              }}
                  />
                )
            },
            {
                name: "Pedidos", selector: row => row.modPedidos, sortable: true,
                cell: (row, index) => (
                  <Form.Check type="switch" defaultChecked={row.modPedidos}
                              id={"modPedidos"+index}
                              onChange={(e) => {
                                  this.handleChangeChk(row, e, index, "modPedidos")
                              }}
                  />
                )
            },
            {
                name: "Exportar", selector: row => row.modExport, sortable: true,
                cell: (row, index) => (
                  <Form.Check type="switch" defaultChecked={row.modExport}
                              id={"modExport"+index}
                              onChange={(e) => {
                                  this.handleChangeChk(row, e, index, "modExport")
                              }}
                  />
                )
            },
            {
                name: "Precio", selector: row => row.modPrecio, sortable: true,
                cell: (row, index) => (
                  <Form.Check type="switch" defaultChecked={row.modPrecio}
                              onChange={(e) => {
                                  this.handleChangeChk(row, e, index, "modPrecio")
                              }}
                  />
                )
            },
            {
                name: "Eliminar", button: true, width: '80px', export: false,
                ignoreRowClick: false,
                allowOverflow: true,
                cell: (row) =>
                  row.name === 'Administrador' ? (
                    <></>
                  ) : <FontAwesomeIcon icon={faTrash} className="hand" onClick={() => {
                      this.setState({
                          openModal: true,
                          idRol: row.id,
                          nameRol: row.name
                      })
                  }}/>

            }
        ]

    }

    componentDidMount() {
        this.getRoles()
    }


    getRoles() {

        services.getModulesByRolMysql().then(response => {
            const infoRoles = response.data
            let dataFinTemp = []
            /**
             * crea los roles unicos
             */
            let dataRoles = infoRoles.reduce((roles, line, index) => {
                roles[line.NAME] = roles[line.NAME] || []
                roles[line.NAME].push(line)
                return roles
            }, {})
            /**
             * asigna los valores al mapeo de las columnas para la tabla
             */
            for (let key in dataRoles) {
                //console.log("dataRoles:",dataRoles[key][0])
                let itemTemp = {
                    id: dataRoles[key][0].ID,
                    name: dataRoles[key][0].NAME,
                    description: dataRoles[key][0].DESCRIPTION,
                    fechaCreacion: dataRoles[key][0].Module,
                    modRoles: false,
                    modUsers: false,
                    modTokens: false,
                    modExist: false,
                    modPedidos: false,
                    modExport: false,
                    modPrecio: false
                }
                for (let keyInterno in dataRoles[key]) {
                    let obj = dataRoles[key][keyInterno]
                    let val = (obj.Module === "true");
                    if (obj.Module === 'roles') itemTemp.modRoles = true;
                    if (obj.Attribute === 'exportacion') itemTemp.modExport = val;
                    if (obj.Attribute === 'precio') itemTemp.modPrecio = val;
                    if (obj.Module === 'existencias') itemTemp.modExist = true;
                    if (obj.Module === 'pedidos') itemTemp.modPedidos = true;
                    if (obj.Module === 'tokens') itemTemp.modTokens = true;
                    if (obj.Module === 'usuarios') itemTemp.modUsers = true;
                }
                dataFinTemp.push(itemTemp)
            }

            //console.log("dataFinTemp:::::",dataFinTemp)
            this.setState({dataRoles: dataFinTemp, loading: false});
            allDataRoles = dataFinTemp

        }).catch((error) => {
            console.log(error)
        })
    }

    /*
    *actualiza el rol dentro del array de datos que estan asignados a la tabla
     */
    handleChangeChk = (row, e, index, nameRol) => {
        allDataRoles[index][nameRol] = e.target.checked
        if(nameRol === "modExist" && !e.target.checked) {
            let id ="modExport"+index
            document.getElementById(id).checked = e.target.checked
            allDataRoles[index]["modExport"] = e.target.checked
            row.modExport = e.target.checked
        }
        if(nameRol === "modExport" && e.target.checked) {
            let id ="modExist"+index
            document.getElementById(id).checked = e.target.checked
            allDataRoles[index]["modExist"] = e.target.checked
            row.modExist = e.target.checked
        }
        this.updateRoles(row, allDataRoles[index])
    }

    /**
     * actualiza los atributos dentro de keycloak
     * crea el array de modulos con su label, ejemplo : "clientes, usuario, roles"
     * @param row
     */
    updateRoles(row,) {
        //console.log("fila:", row)
        let dataModules = []
        for (const key in row) {
            if ("modRoles" === key && row[key]) dataModules.push(labelModules[key])
            if ("modUsers" === key && row[key]) dataModules.push(labelModules[key])
            if ("modTokens" === key && row[key]) dataModules.push(labelModules[key])
            if ("modExist" === key && row[key]) dataModules.push(labelModules[key])
            if ("modPedidos" === key && row[key]) dataModules.push(labelModules[key])
        }

        const body = {
            "id": row.id,
            "name": row.name,
            "description": row.description,
            "composite": false,
            "clientRole": true,
            "containerId": "2758d422-8dff-4b47-9706-40559e829d47",
            "attributes": {
                "fechaCreacion": [row.fechaCreacion],
                "exportacion": [row.modExport],
                "precio": [row.modPrecio],
                "modulos": dataModules
            }
        }
        //console.log("body:::", body)
        services.updateAttributeRole(row.id, body).then(response => {
            //console.log("regreso actualización :", response)

            this.handlerShowUpdateRol()
        }).catch(error => {
            console.log("icintracom - actualiar rol:", error.message)
        })

    }

    onDeleteItem = (row) => {
        services.deleteRole(this.state.idRol).then(resp => {
            this.setState({
                openModal: false,
                show: false,
                idRol: "",
                nameRol: ""
            })
            this.handlerOpenAlert()
            this.getRoles()

        })
    }

    handlerOpenAlertAdd = () => this.setState({showAlertAddRol: true})
    handlerCloseAlertAdd = () => this.setState({showAlertAddRol: false})

    handlerOpenAlert = () => this.setState({showUpdateAlert: true})
    handlerCloseAlert = () => this.setState({showUpdateAlert: false})

    handlerShowUpdateRol = () => this.setState({showUpdateRol: true})
    handlerCloseUpdateRol = () => this.setState({showUpdateRol: false})


    onCloseModal = () => {
        this.setState({openModal: false})
    }

    render() {

        return (
          <>

              <Container fluid>
                  <AlertIcintracom title={"Rol"}
                                   text={"¡Se agrego exitosamente el rol!"}
                                   show={this.state.showAlertAddRol}
                                   close={this.handlerCloseAlertAdd}
                                   style={{margin: "4rem"}}
                                   type={"success"}/>

                  <AlertIcintracom dismissible
                                   show={this.state.showUpdateAlert}
                                   variant="success"
                                   close={this.handlerCloseAlert}
                                   style={{margin: "4rem"}}
                                   title="Rol"
                                   text="¡Se ha eliminado el rol !"
                                   type={"success"}/>

                  <AlertIcintracom dismissible
                                   show={this.state.showUpdateRol}
                                   variant="success"
                                   close={this.handlerCloseUpdateRol}
                                   style={{margin: "4rem"}}
                                   title="Rol"
                                   text="¡Se ha actualizo el rol !"
                                   type={"success"}/>
                  <Row className="justify-content-md-center">
                      <Col sm={10}>
                          <div style={styles.columnText}>
                              <h1 id="lbl_title_admin_roles">Administración de Roles</h1>
                          </div>
                      </Col>

                  </Row>
                  {

                      this.state.loading === false ?
                        <Row className="justify-content-md-center" style={styles.topRow}>

                            <Col sm={6}>
                                <div>
                                    <p>
                                        <label>Total roles : <b>{this.state.dataRoles.length}</b></label>
                                    </p>
                                </div>
                            </Col>
                            <Col sm={3}></Col>
                            <Col sm={2}>
                                <AddRol getRoles={this.getRoles} handlerOpenAlertAdd={this.handlerOpenAlertAdd}></AddRol>
                            </Col>
                        </Row>
                        : <Row className="justify-content-md-center"> </Row>

                  }
                  <p></p>
                  <Row className="justify-content-md-center">
                      <Col sm={11}>

                          <Datatable
                            columns={this.columns}
                            data={allDataRoles}
                            pagination
                            responsive
                            striped
                            defaultSortField="name"
                            progressPending={this.state.loading}
                            progressComponent={<CustomLoader />}
                            noDataComponent={"No existen registros"}
                            paginationComponentOptions={{
                                rowsPerPageText: 'Registros por página:',
                                rangeSeparatorText: 'de',
                            }}
                          />
                      </Col>

                  </Row>
              </Container>


              <Modal show={this.state.openModal} onHide={this.onCloseModal}>
                  <Modal.Header closeButton>
                      <Modal.Title>Eliminar Rol</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="pure-form">
                      <div>
                          ¿Eliminar el Rol <b>{this.state.nameRol}</b>?
                      </div>
                      <p/>
                      <Modal.Footer>
                          <Button variant="primary" onClick={this.onDeleteItem}>Aceptar</Button>
                          <Button variant="secondary" onClick={this.onCloseModal}>Cancelar</Button>
                      </Modal.Footer>
                  </Modal.Body>

              </Modal>

          </>
        )
    }

}

export default AdminRoles;