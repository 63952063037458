import React from 'react';
import {BrowserView, MobileView, Orientation} from "../DetectDevice";
import AdminRoles from "../../AdminRoles";
import {ImgRotate} from "../ImgRotate"

export const ProxyRoles = () => {
  let isLandScape = Orientation.isLandScape
  return (
    <>
      <BrowserView>
        <AdminRoles/>
      </BrowserView>
      <MobileView>
        {!isLandScape && <ImgRotate/>}
        {isLandScape && <AdminRoles/>}
      </MobileView>
    </>
  )
}