import  'purecss'
import './App.css';
import  React from 'react';
import { BrowserRouter , Route, Routes} from 'react-router-dom';
import Home from "./pages/Homepage";
import {ProxyUsers} from "./pages/components/Proxys/ProxyUsers";
import {ProxyRoles} from "./pages/components/Proxys/ProxyRoles";
import {ProxyTokens} from "./pages/components/Proxys/ProxyTokens";
import {ProxyExistencias} from "./pages/components/Proxys/ProxyExistencias";
import {ProxyOrders} from "./pages/components/Proxys/ProxyOrders";

import Welcome from "./pages/Welcome";

function App(){
    const route = "/"//Produccion <Route exact path="/" element={<Home />} >
    //const route = "/pruebasicm/" //Pruebas
    React.useState(false);
    React.useState(0)
    return(
      <div>
          <BrowserRouter>
              <Routes>
                  <Route exact path={route} element={<Home />} >
                      <Route index element={<Welcome />} />
                      <Route exact path={route + "/AdministracionRoles"} element={<ProxyRoles />}/>
                      <Route exact path={route +"/AdministracionUsuarios"} element={<ProxyUsers />} />
                      <Route exact path={route +"/AdministracionTokens"} element={<ProxyTokens />} />
                      <Route exact path={route +"/Existencias"} element={<ProxyExistencias/>} />
                      <Route exact path={route +"/Pedidos"} element={<ProxyOrders/>} />

                  </Route>
              </Routes>
          </BrowserRouter>
      </div>
    );
}

export default App;