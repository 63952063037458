import React from 'react';
import {BrowserView, MobileView, Orientation} from "../DetectDevice";
import Existencias from "../../Existencias";
import {ImgRotate} from "../ImgRotate"

export const ProxyExistencias= () => {
  let isLandScape = Orientation.isLandScape
  return (
    <>
      <BrowserView>
        <Existencias/>
      </BrowserView>
      <MobileView>
        {!isLandScape && <ImgRotate/>}
        {isLandScape && <Existencias/>}
      </MobileView>
    </>
  )
}