import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {BrowserView, MobileView} from "../pages/components/DetectDevice";
import img_welcome from "../img/image_home.png";


class Welcome extends React.Component {

    render() {

        return (
          <>
              <Container style={{padding: "1rem 5rem 3rem 3rem"}}>
                  <BrowserView>
                      <Row className="justify-content-md-center">
                          <Col md="auto" className="img_logo_welcome">
                              <br/>
                              <img src={img_welcome}/>
                          </Col>
                      </Row>
                  </BrowserView>
                  <MobileView>
                      <img src={img_welcome} className="img_logo_welcome_mobile"/>
                  </MobileView>
              </Container>
          </>
        )
    }

}

export default Welcome;