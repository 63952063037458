import axios from 'axios';
import keycloak from "./UserService";


class InstanceAxios{

    instance = {}

    getInstance(){
        return this.instance;
    }

    setToken(token){
        this.instance = axios.create({
            baseURL: keycloak.authServerUrl(),
            headers: {
                'Authorization': `Bearer ${token}`
            },
            'Content-Type': 'application/json'
        })
    }
}

const singletonAxios = new InstanceAxios();


export default singletonAxios;