import Keycloak from "keycloak-js";
import singletonAxios from "./IntanceAxios";
import singletonSession from "./IntanceSession";
import ReactDOM from "react-dom/client";
import App from '../App';
import  React from 'react';
import * as UserServiceSession from "./UserServiceSession";

let keycloak = new Keycloak({
  "url": process.env.REACT_APP_HOST_KEYCLOAK+":"+process.env.REACT_APP_PORT_KEYCLOAK,
  "realm": "Icintracom",
  "clientId": 'icintracom_app'
});

/*const initKeycloak = (onAuthenticatedCallback) => {
    keycloak.init({
        "onLoad": "login-required",
        "checkLoginIframe": false })
        .then((auth)=>{
            if (!auth) {
                console.log("¡Usuario NO permitido!");
            }
            let user = getUsername()
            window.localStorage.setItem("username", user);
            singletonSession.setUser(user)
            window.localStorage.accessToken = keycloak.token;
            singletonAxios.setToken(keycloak.token)
           // ReactDOM.createRoot(document.getElementById("root")).render(<React.StrictMode><App/></React.StrictMode>);
            onAuthenticatedCallback();
        })
        .catch(console.error)

};*/
const initKeycloak = (onAuthenticatedCallback) => {
  return new Promise((resolve, reject) => {
    keycloak.init({
      "onLoad": "login-required",
      "checkLoginIframe": false
    })
      .then((auth) => {
        if (!auth) {
          console.log("¡Usuario NO permitido!");
          reject("Usuario NO permitido");
        }

        let user = gerUser();
        window.localStorage.setItem("username", user);
        singletonSession.setUser(user);
        window.localStorage.accessToken = keycloak.token;
        singletonAxios.setToken(keycloak.token);
        UserServiceSession.initSession()
        ReactDOM.createRoot(document.getElementById("root")).render(
          <React.StrictMode><App/></React.StrictMode>);
        onAuthenticatedCallback();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

const doLogin = keycloak.login;
const doLogout  = keycloak.logout;
const isLoggedIn = ()=> !!keycloak.token;
const getToken = () => keycloak.token;
const getUsername = () => keycloak.tokenParsed?.given_name;
const gerUser = () => keycloak.tokenParsed?.preferred_username;
const getModules = ()=> keycloak.idTokenParsed?.modulos;
const getUserID = ()=> keycloak.idTokenParsed?.sub;
const authServerUrl = ()=> keycloak.authServerUrl;
const realm = () => {
  return keycloak.realm;
}
const updateToken = (calback) => keycloak.updateToken(5)
  .then(calback)
  .catch(doLogin)

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getUserID,
  getUsername,
  getModules,
  getToken,
  authServerUrl,
  realm,
  updateToken,
  gerUser
};


export default UserService;