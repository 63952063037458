import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import UserService from "./services/UserService";
import App from "./App"
import singletonMysqlApis from "./services/IntanceMysqlApis";


const initializeApp = () => {
    let user = window.localStorage.getItem("username");
    let session = window.localStorage.getItem("session");

    if ((session !== null && session !== undefined && session !== "") || (user !== null && user !== undefined && user !== "")) {
        const renderApp = () => ReactDOM.createRoot(document.getElementById("root")).render(
          <React.StrictMode><App/></React.StrictMode>);

        UserService.initKeycloak(renderApp);
    } else {
        const renderApp = () => ReactDOM.createRoot(document.getElementById("root")).render(
          <React.StrictMode><App/></React.StrictMode>);

        UserService.initKeycloak(renderApp);
        let token = window.localStorage.getItem("session")
        singletonMysqlApis.setToken(token)
    }
}


initializeApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();